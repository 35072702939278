import { render, staticRenderFns } from "./wbgl.vue?vue&type=template&id=bb50057a&scoped=true&"
import script from "./wbgl.vue?vue&type=script&lang=js&"
export * from "./wbgl.vue?vue&type=script&lang=js&"
import style0 from "./wbgl.vue?vue&type=style&index=0&id=bb50057a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb50057a",
  null
  
)

export default component.exports