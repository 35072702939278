<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!-- 确认所在园区  -->
              <div class="range" id="glhtrk">
                <h4 class="title">管理后台入口</h4>
                <el-divider></el-divider>
                <p class="enterance">
                  <ul>
                    <li>1.智慧湾：https://admin.kefan.com/</li>
                    <li>2.智慧坊：https://zhf.wis-p.cn/</li>
                    <li>3.交运智慧湾：https://jyzhw.jy-wisdombay.cn/</li>
                    <li>4.智慧七立方：https://wis777.kefan.com/</li>
                    <li>5.科房旗下园区：https://kf.kefan.com/</li>
                  </ul>
                </p>
              </div>
              <!-- 维保管理 -->
              <div class="range" id="wbgl">
                <h4 class="title">维保管理</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击左侧页面下方蓝色按钮“维保管理”（如图6.1）
                </p>
                <el-image 
                style="width:100%"
                :src="img.aurl[0]"
                :preview-src-list="img.aurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.1</p>
              </div>

              <!-- 维保项目管理 -->
              <div class="range" id="wbxmgl">
                <h4 class="title">维保项目管理</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击“项目管理”即可跳出维保项目信息列表（如图6.2）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.burl[0]"
                :preview-src-list="img.burl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.2</p>
              </div>
              <!-- 添加维保项目 -->
              <div class="range" id="tjwbxm">
                <h4 class="title"> 添加维保项目</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击右上方蓝色按钮“添加项目”（如图6.3）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.curl[0]"
                :preview-src-list="img.curl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.3</p>
                <p class="describe">
                  2.跳出项目管理添加方框（如图6.4），填入维保公司、项目名称、项目分类、备注，上传图片后点击保存即可完成维保项目添加
                </p>
                <el-image 
                style="width:100%"
                :src="img.durl[0]"
                :preview-src-list="img.durl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.4</p>
                <p class="describe">
                  3.点击红色方框画出的“添加人员”按钮（如图6.5）可添加维保人员信息（如图6.6）姓名和手机号
                </p>
                <el-image 
                style="width:100%"
                :src="img.eurl[0]"
                :preview-src-list="img.eurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.5</p>
                <el-image 
                style="width:100%"
                :src="img.furl[0]"
                :preview-src-list="img.furl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.6</p>
              </div>
               <!-- 添加分类 -->
              <div class="range" id="tjfl">
                <h4 class="title">添加分类</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击按钮“项目分类”，跳转至项目分类列表页面，可对项目类别进行添加、修改和删除（如图6.7）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.gurl[0]"
                :preview-src-list="img.gurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.7</p>
              </div>
              <!-- 维保记录 -->
              <div class="range" id="wbjl">
                <h4 class="title">维保记录</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击按钮“维保记录”，跳转至维保记录列表（如图6.8）可筛选维保记录并对其进行删除和修改
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.hurl[0]"
                :preview-src-list="img.hurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.8</p>
              </div>
              <!-- 维保人员 -->
              <div class="range" id="wbry">
                <h4 class="title">维保人员</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击按钮“维保人员”跳转至维保人员列表页面（如图6.9）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.iurl[0]"
                :preview-src-list="img.iurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.9</p>
                <p class="describe">
                     1.点击右上方蓝色按钮“添加”即可添加维保人员并可对其进行删除和拒绝访问（如图6.10）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.jurl[0]"
                :preview-src-list="img.jurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.10</p>
              </div>

              <!-- 维保合同 -->
              <div class="range" id="wbht">
                <h4 class="title">维保合同</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击按钮“维保合同”跳转至维保合同列表页面（如图6.11），点击蓝色字体合同名称可直接跳转至目标合同内容，最右侧可删除合同
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.kurl[0]"
                :preview-src-list="img.kurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.11</p>
                <p class="describe">
                     2.点击页面右上方“添加合同”（如图6.12）
                </p>
                    <el-image 
                style="width:100%"
                :src="img.lurl[0]"
                :preview-src-list="img.lurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.12</p>
                <p class="describe">
                     3.跳出合同内容方框，填写对应合同内容，点击保存完成合同添加（如图6.13）</p>
                    <el-image 
                style="width:100%"
                :src="img.murl[0]"
                :preview-src-list="img.murl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.13</p>
              </div>
              <!-- 维保二维码 -->
              <div class="range" id="wbewm">
                <h4 class="title">维保二维码</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.点击按钮“维保二维码”即可跳出维保微信二维码（如图6.14）
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.nurl[0]"
                :preview-src-list="img.nurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.14</p>
                <p class="describe">
                     2.手机扫描二维码后即跳转至维保页面（如图6.15）
                    </p>
                    <el-image 
                style="width:30%"
                :src="img.ourl[0]"
                :preview-src-list="img.ourl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.15</p>
              </div>

               <!-- 小程序维保记录查看 -->
              <div class="range" id="xcxwbjlck">
                <h4 class="title">小程序维保记录查看</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1.进入园区小程序，选择下方服务中心，再点击右下方维保记录（如图6.16）
                    </p>
                    <el-image 
                style="width:30%"
                :src="img.purl[0]"
                :preview-src-list="img.purl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.16</p>
                <p class="describe">
                     2.即可跳转出维保项目，点击后可查看详情（如图6.17）
                    </p>
                    <el-image 
                style="width:30%"
                :src="img.qurl[0]"
                :preview-src-list="img.qurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图6.17</p>
              </div>



              
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      lists: [
        {
          name: "管理后台入口",
          target: "#glhtrk",
        },
        {
          name: "维保管理",
          target: "#wbgl",
        },
        {
          name: "维保项目管理",
          target: "#wbxmgl",
        },
        {
          name: "添加维保项目",
          target: "#tjwbxm",
        },
        {
          name: "添加分类",
          target: "#tjfl",
        },
        {
          name: "维保记录",
          target: "#wbjl",
        },
        {
          name: "维保人员",
          target: "#wbry",
        },
        {
          name: "维保合同",
          target: "#wbht",
        },
        {
          name: "维保二维码",
          target: "#wbewm",
        },
        {
          name: "小程序维保记录查看",
          target: "#xcxwbjlck",
        },
      ],
      link: 0,
      img: {
        aurl: [this.$img + "wbgl/6.1.png"],
        burl: [this.$img + "wbgl/6.2.png"],
        curl: [this.$img + "wbgl/6.3.png"],
        durl: [this.$img + "wbgl/6.4.png"],
        eurl: [this.$img + "wbgl/6.5.png"],
        furl: [this.$img + "wbgl/6.6.png"],
        gurl: [this.$img + "wbgl/6.7.png"],
        hurl: [this.$img + "wbgl/6.8.png"],
        iurl: [this.$img + "wbgl/6.9.png"],
        jurl: [this.$img + "wbgl/6.10.png"],
        kurl: [this.$img + "wbgl/6.11.png"],
        lurl: [this.$img + "wbgl/6.12.png"],
        murl: [this.$img + "wbgl/6.13.png"],
        nurl: [this.$img + "wbgl/6.14.png"],
        ourl: [this.$img + "wbgl/6.15.jpg"],
        purl: [this.$img + "wbgl/6.16.png"],
        qurl: [this.$img + "wbgl/6.17.jpg"],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getscroll);
  },
  methods: {
    tolink(index) {
      this.link = index;
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (result < 2) {
        this.tolink(0);
      } else if (result >= 2  && result < 8) {
        this.tolink(1);
      } else if (result >= 8 && result < 14) {
        this.tolink(2);
      } else if (result >= 14 && result < 37) {
        this.tolink(3);
      } else if (result >= 37 && result < 42) {
        this.tolink(4);
      } else if (result >= 42 && result < 50) {
        this.tolink(5);
      } else if (result >= 50 && result < 60) {
        this.tolink(6);
      } else if (result >= 60 && result < 75) {
        this.tolink(7);
      } else if (result >= 75 && result < 89) {
        this.tolink(8);
      } else {
        this.tolink(9);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.enterance {
  background: #5e6d82;
  color: aliceblue;
  border-radius: 8px;
  font-size: 14px;
}
.enterance li {
  padding: 5px 10px;
}
.describe::first-letter {
  font-size: 20px;
  font-weight: 700;
  text-shadow: 3px 2px 2px #4fc08d;
}
</style>